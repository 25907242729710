.card {
  width: 90%;
  height: 80%;
  margin: auto;
  min-height: 512px;
  box-shadow: 0px 35px 70px 0px rgba(255, 163, 125, 0.65), 0px 30px 40px -10px rgba(255, 100, 124, 0.55);
  background: linear-gradient(180deg, #064B73, #a94c4c);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 512px) {
  .card {
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
  }
}

.hero {
  background-image: url(./assets/icons/gop_logo.jpeg);
}

.header {
  display: flex;
  padding: 48px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 10%;
  width: 100%;
}

@media (max-width: 991px) {
  .header {
    padding: 15px;
    top: 0;
    width: 100%;
    flex-direction: column;
    bottom: auto;
  }
}

.logo {
  font-weight: bold;
  font-size: 2.7em;
  transition: opacity 0.05s ease;
  text-align: left;
  color: #212529;
  margin-left: 50px;
  margin-top: 20px;
  flex: 3;
}

.logo:hover {
  opacity: 0.75;
}

@media (max-height: 750px) and (min-width: 991px) {
  .logo {
    text-align: left;
    color: #212529;
    margin-left: 50px;
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  .logo {
    text-align: center;
    color: #fff;
    margin-left: 0px;
    margin-top: opx;
  }
}

.social {
  display: flex;
  position: absolute;
  right: 135px;
}

.social a {
  display: inline-block;
  margin-right: 16px;
  transition: opacity 0.05s ease;
}

.social a:last-child {
  margin-right: 0;
}

.social a:hover {
  opacity: 0.75;
  transform: scale(1.5);
}

.social .icon {
  width: 22px;
  fill: #212529;
}

.social .icon:hover {
  fill: #212529;
}

@media (max-width: 991px) {
  .social .icon {
    fill: #fff;
  }
  
  .social .icon:hover {
    fill: #fff;
  }
}

.button-icon .icon {
  width: 25px;
  fill: #fff;
  opacity: 0.75;
}

.button-icon .icon:hover {
  transform: 1.1;
  fill: #fff;
  opacity: 1;
}

.content-container .button {
  margin-top: 10px;
  margin-bottom: 15px;
}

.italic-font {
  font-style: italic;
}

@media (max-width: 991px) {
  .social {
    text-align: center;
    position: relative;
    left: 0;
    right: 0;
  }
}

.content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  min-height: 256px;
}

.content-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-left .image-holder img {
  border-radius: 160px;
  box-shadow: 0px 0px 15px 0px rgba(255, 163, 125, 0.65), 0px 30px 40px -10px rgba(255, 100, 124, 0.55);
}

.content-left .title-holder {
  color: #fff;
  text-align: center;
  margin-top: 35px;
  margin-bottom: 35px;
}

.content-left .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.content-left .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .content-left .title-holder {
    max-width: 40%;
  }
}

.content-left .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content-left .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.content-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.content-right .title-holder {
  color: #fff;
  text-align: center;
  margin-bottom: 24px;
}

.content-right .title-holder h1 {
  font-weight: bold;
  font-size: 36px;
  margin-bottom: 12px;
}

.content-right .title-holder p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 768px) {
  .content-right .title-holder {
    max-width: 80%;
  }
}

.content-right .cta {
  min-width: 64px;
  padding: 16px 24px;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  transform: none;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s cubic-bezier(0.25, 0.25, 0.315, 1.35), transform 0.1s linear;
}

.content-right .cta:hover {
  transform: translateY(-1px);
  box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.3);
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 48px;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  position: relative;
  bottom: 0;
  right: 0;
  background: #f02525;
}

@media (max-width: 991px) {
  .footer {
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@media (max-height: 750px) and (min-width: 991px) {
  .home .home-details-container {
    overflow-y: auto;
  }
}

a.underlined:hover {
  border-bottom: 1px dotted #fff;
}